// http://ionicframework.com/docs/theming/
@import '@ionic/angular/css/core.css';
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';

@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';
@import '@ng-select/ng-select/themes/default.theme.css';
@import 'tippy.js/dist/tippy.css';
@import 'tippy.js/themes/light.css';
@import 'tippy.js/animations/scale.css';

ion-button {
    text-transform: none !important;
}

ion-item {
    ion-input {
        border-width: 0 !important;
        input {
            border-width: 0 !important;
        }
    }
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
ion-header {
    z-index: auto;
}
ion-modal {
    --width: 80% !important;
    --height: 80% !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}
.card {
    background: #fff;
    padding: 20px;
    border-radius: 15px;
    margin-bottom: 15px;
}
.ion-page-invisible {
    opacity: 1 !important;
}

.break-word {
    h3 {
        word-wrap: break-word;
        white-space: pre-wrap;
    }
}

.scroll-content {
    overflow: auto !important;
}

div.container.head {
    margin-top: 20px;
    margin-bottom: 20px;
}

.no-padding {
    padding: 0 !important;
}

.sucess {
    background: #0ee0a6;
    border: 1px solid #298e3a !important;
}

.invalid {
    background: #e46060;
    border: 1px solid #b32020 !important;
}

/**
* Google Maps Style
*/

.gmnoprint[style*='z-index: 10;'] {
    margin: 10px 15px !important;
}

div[title='Zeichnung beenden'],
div[title='Form zeichnen'] {
    top: 0;
    left: -5px;
    padding: 10px !important;
}

div[title='Satellitenbilder anzeigen'],
div[title='Stadtplan anzeigen'] {
    height: 36px !important;
    padding: 11px !important;
}

div[title='Vollbildansicht ein/aus'] {
    display: none !important;
}

a[title='Klicken, um diese Region in Google Maps anzuzeigen'] {
    margin-bottom: 20px !important;
    display: block !important;
}

.head-title {
    font-size: 4rem;
    font-weight: lighter;
    color: #333;
    position: relative;

    img {
        // height: 90px;
        width: auto;
    }

    strong {
        color: #303742;
    }

    small {
        font-size: 16px;
    }

    sup {
        position: absolute;
        top: -0.1em;
    }
}

@keyframes shadow-pulse {
    0% {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.3);
    }

    100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
}

.spacer {
    display: block;
    height: 120px;
}
.custom-form {
    background: #fff;
    border-radius: 5px;
    padding: 0;
    overflow: hidden;
    ion-row,
    ion-col,
    ion-grid {
        --ion-grid-column-padding: 0;
    }
    .row {
        margin: 0;
    }
}

.divider {
    position: relative;
    opacity: 0.2;
    padding: 30px 0;
    margin: 0;
    text-transform: uppercase;
    &:before {
        border-top: 2px solid #dfdfdf;
        content: '';
        margin: 0 auto;
        /* this centers the line to the full width specified */
        position: absolute;
        /* positioning must be absolute here, and relative positioning must be applied to the parent */
        top: 50%;
        left: 0;
        right: 0;
        bottom: 0;
        width: 95%;
        z-index: -1;
    }
    span {
        /* to hide the lines from behind the text, you have to set the background color the same as the container */
        background: #fff;
        padding: 0 15px;
    }
}
.dbox {
    border-radius: 3px;
    overflow: hidden;
    input {
        outline: none;
        box-shadow: 0 0 0;
        border: none;
        border-radius: 0;
        height: 40px;
        color: #747474;
        font-size: 13px;
        padding: 8px 15px;
        box-shadow: inset 0 -0.1px 0 #303742;
        &:focus {
            outline: 0;
            box-shadow: inset 0 -1.5px 0 #303742;
        }
    }
    .inputs {
        width: 80%;
    }
    .two-boxes {
        width: 100%;
        input.form-control {
            float: left;
            &:nth-child(2) {
                border-left-width: 0;
            }
        }
    }
}

.swal2-styled.swal2-confirm {
    background-color: var(--primary) !important;
}
.swal2-styled.swal2-confirm:focus {
    box-shadow: 0 0 0 3px rgb(150 186 215) !important;
}
.swal2-icon.swal2-info {
    border-color: var(--primary) !important;
    color: var(--primary) !important;
}
html body.swal2-height-auto {
    height: 100vh !important;
}

.card {
    @apply bg-white p-5 rounded-xl shadow-lg;
    h1,
    h2 {
        @apply text-2xl font-bold text-gray-800 mb-3 mt-0;
    }
    h2 {
        @apply text-xl;
    }
}

ion-content {
    --background: #e6ebef;
}

@keyframes hotToastEnterAnimationNegative {
    0% {
        opacity: 0.5;
        transform: translate3d(0, -80px, 0) scale(0.6);
    }

    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0) scale(1);
    }
}

@keyframes hotToastEnterAnimationPositive {
    0% {
        opacity: 0.5;
        transform: translate3d(0, 80px, 0) scale(0.6);
    }

    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0) scale(1);
    }
}

@keyframes hotToastExitAnimationPositive {
    0% {
        opacity: 1;
        transform: translate3d(0, 0, -1px) scale(1);
    }

    100% {
        opacity: 0;
        transform: translate3d(0, 130px, -1px) scale(0.5);
    }
}

@keyframes hotToastExitAnimationNegative {
    0% {
        opacity: 1;
        transform: translate3d(0, 0, -1px) scale(1);
    }

    100% {
        opacity: 0;
        transform: translate3d(0, -130px, -1px) scale(0.5);
    }
}

ion-item {
    &.ion-invalid {
        border-bottom: 1px solid red;
    }
}
